import { createSlice } from "@reduxjs/toolkit";

const userCenterSlice = createSlice({
    name: "userCenter",
    initialState: {
        memberInfo: {},
        isPersonalDataEditable: false, //帳戶資料-基本訊息是否可編輯
        isNewMemFirstLogin: false,
    },
    reducers: {
        setMemberInfo(state, action) {
            state.memberInfo = { ...state.memberInfo, ...action.payload };
            const { firstName, dob, gender } = action.payload;
            console.log(
                "firstName",
                firstName,
                "dob",
                dob,
                "gender",
                gender
            );
            if (firstName && firstName.length && dob && gender) {
                state.isPersonalDataEditable = false;
            } else {
                state.isPersonalDataEditable = true;
            }
        },
        setMemberFirstLogin(state, action) {
            state.isNewMemFirstLogin = action.payload;
        },
        resetData(state) {
            state.memberInfo = {};
            state.isPersonalDataEditable = false;
            state.isNewMemFirstLogin = false;
        },
    },
});

export default userCenterSlice;
export const userCenterActions = userCenterSlice.actions;
