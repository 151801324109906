import { ApiPort } from "$ACTIONS/TLCAPI";
import { get } from "$ACTIONS/TlcRequest";
import { commonDataActions } from "$STORE/commonDataSlice";

/**
 * 取得該browser能支援的image (avif / webp)
 * @returns
 */
export const getImageSupportFormat = () => {
    return async (dispatch, getState) => {
        const supportFormat = [];
        await new Promise((resolve) => {
            const img = new Image();
            img.src = "data:image/avif;base64,AAAAHGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZgAAAPBtZXRhAAAAAAAAAChoZGxyAAAAAAAAAABwaWN0AAAAAAAAAAAAAAAAbGliYXZpZgAAAAAOcGl0bQAAAAAAAQAAAB5pbG9jAAAAAEQAAAEAAQAAAAEAAAEUAAAAFQAAAChpaW5mAAAAAAABAAAAGmluZmUCAAAAAAEAAGF2MDFDb2xvcgAAAABoaXBycAAAAElpcGNvAAAAFGlzcGUAAAAAAAAAAQAAAAEAAAAOcGl4aQAAAAABCAAAAAxhdjFDgQAcAAAAABNjb2xybmNseAABAAEAAQAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAAB1tZGF0EgAKBxgADlgICAkyCB/xgAAghQm0";
            img.onload = () => {
                supportFormat.push("avif");
                return resolve(true); // 支持 AVIF
            };
            img.onerror = () => resolve(false); // 不支持 AVIF
        });
        await new Promise((resolve) => {
            const img = new Image();
            img.src = "data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA";
            img.onload = () => {
                supportFormat.push("webp");
                return resolve(true); // 支持 WEBP
            };
            img.onerror = () => resolve(false); // 不支持 WEBP
        });

        return dispatch(commonDataActions.setImageSupportFormats(supportFormat));
    };
};

/**
 * 從CMS取得Footer images
 * @returns
 */
export const getCMSFooter = () => {
    return async (dispatch, getState) => {
        try {
            const res = await get(ApiPort.GETCmsFooterImages);

            return dispatch(commonDataActions.setCmsFooterImages(res?.data));
        } catch (err) {
            console.error("getCMSFooter error:", err);
        }
    };
};
