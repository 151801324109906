import { createSlice } from "@reduxjs/toolkit";

export const DEFAULT_AFFILIATE_URL = "https://www.fun6606.com/lmr/th-th/";

const mainSiteConfigSlice = createSlice({
    name: "mainSiteConfig",
    initialState: {
        affiliateUrl: DEFAULT_AFFILIATE_URL,
    },
    reducers: {
        setAffiliateUrl(state, action) {
            state.affiliateUrl = action.payload || DEFAULT_AFFILIATE_URL;
        },
    },
});

export default mainSiteConfigSlice;

export const mainSiteConfigActions = mainSiteConfigSlice.actions;
