import { createSlice } from "@reduxjs/toolkit";

const commonDataSlice = createSlice({
    name: "commonData",
    initialState: {
        imageSupportFormats: [], //圖片支援格式 優先度: AVIF > WEBP > default (一般常用圖片格式jpg, png, svg...)
        cmsFooterImages: null, //取得CMS footer images
    },
    reducers: {
        setImageSupportFormats(state, action) {
            state.imageSupportFormats = action.payload;
        },
        setCmsFooterImages(state, action) {
            state.cmsFooterImages = action.payload;
        },
    },
});

export default commonDataSlice;
export const commonDataActions = commonDataSlice.actions;
