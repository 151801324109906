/*
 * @Author: Alan
 * @Date: 2023-06-14 15:11:45
 * @LastEditors: Alan
 * @LastEditTime: 2023-06-17 16:09:26
 * @Description: 正则
 * @FilePath: /F1-M2-WEB-Code/actions/reg.js
 */
// 用户密码
export const pwdRegOld =
    /^(?=.{6,20}$)(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9])+$/;
// export const pwdReg = /^(?=.{6,20}$)(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[\^#$@]*)([\^#$@]*[a-zA-Z0-9][\^#$@]*)+$/
// 统一App用户密码格式
export const pwdReg =
    /^(?=.{6,20}$)(?=.*[0-9])(?=.*[A-Za-z])[0-9A-Za-z\^\#\$\@]+$/;

export const phoneReg = /^(\+?86\-?)?1[0-9]{2}[0-9\*]{6}[0-9]{2}$/;

// 用户名登陆格式限制
export const userName_reg = /^[a-zA-Z0-9]{6,14}$/;

// 禁止出现结尾空格
export const trimReg = /\s+$/;

//逐字選取非英數
export const pickNotAlphaNumeric = /[^\da-zA-Z]/g
//逐字選取非數字
export const pickNotNumber = /[^\d]/g
//逐字選取非數字，且第一位不可為0
export const pickNotNumberWithFirstNumberNon_zero = /^0|[^\d]/g;
//逐字選取非數字，只允許輸入一個.(小數點)，且若為整數的數字第一位不可為0
export const pickNotNumberAllowDecimal= /^[^0-9]|^0*(?=[^\.])|[^0-9\.]|\.(?=.*\.)/g 

//驗證輸入內容不包含空白及符號
export const onlyAlphanumeric = /^[0-9a-zA-Z]*$/;

/**
 * 安全屋登陆API用户名密码正则
 * username ---> ^[a-zA-Z0-9]{5,16}$
 * password ---> ^(?=.{6,16}$)(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9\^#\$@])+$
 */

// 用户真实姓名(中文)
export const realyNameReg = /^[\u4E00-\u9FA5\uf900-\ufa2d·s]{2,15}$/;
// 用户真实姓名(泰文)
export const realyNameRegThai =
    /^(?=.{2,50}$)([a-zA-Z]+\s){1,5}([a-zA-Z]+){1}$|^(?=.{2,50}$)([\u0E00-\u0E7F]+\s){1,5}([\u0E00-\u0E7F]+){1}$/;
// 逐字選取泰文中non-spacing mark字元，用來計算肉眼可看到的泰文字長度
export const thaiNSMReg =
    /[\u0E31\u0E34-\u0E39\u0E3A\u0E47-\u0E49\u0E4A\u0E4E]/g;
//逐字選取不符合泰文姓名的字元(第一個字無法輸入空白)
export const pickNotFitRealNameThaiReg =
    /^\s+|[^a-zA-Z\u0E00-\u0E7F @#$%^&*()_:;"'<>?=+,.-]/g;

// 充值账号后6位
export const sixLastNumReg = /^[0-9]{6}$/;

// OTP 6位
export const otpNumReg = /^[0-9]{6}$/;

// 存款金额限制小数后两位
export const depositMoneyDecimal =
    /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;
// 存款金额限制整数
export const depositMoneyInt = /^[0-9]*$/;

// Email 格式
export const emailReg =
    /^[A-Za-z0-9_]+[a-zA-Z0-9_\.\-]{0,}@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
export const emailReg2 = /^[^_.-].*[^/_.-]$/; // 不能以_.-開頭結尾

// 手機格式
export const regPhone = /[0-9]{9}/;
export const phone_reg = /^0|[^\d]/;

// QQ格式
export const regQQ = /^[0-9]*$/;

// 微信格式
export const regWeChat = /^[0-9a-zA-Z_]*$/;

// LINE
export const pickNonLineChar = /[^a-z\.\-_0-9]/g; //逐字選取非LineID創建規則字元 (僅能輸入數字，英文小寫及.-_)
export const regLine = /^[a-z\.\-_0-9]{4,20}$/; //驗證是否符合LineID創建規則 (僅能輸入數字，英文小寫及.-_)

// FB
export const regFaceBook = /^[a-zA-Z\u0E00-\u0E7F\.0-9 ]{5,50}$/;

// PT电子账户用户名格式
export const ptAccountReg = /^[a-zA-Z0-9]{6,16}$/;

// 登陆用户名密码格式限制
export const regforLogin = /^.+$/;
// 登陆用户名密码非法格式限制
export const regforLoginReg = /[<>\/\\="'\s.]/g;

// 身份证号码格式验证
// export const memberId = /^[1-9][0-9]{5}(19[0-9]{2}|200[0-9]|2010)(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])[0-9]{3}[0-9xX]$/i;
export const memberId = /^[0-9]{17}[0-9X]$/i;

// 银行卡号基础验证
export const bankNumberReg = /^[0-9]{14,19}$/;

//地址 中英文 + 数字 +常用的标点符号
export const addressReg =
    /^[\u4E00-\u9FA5A-Za-z0-9\@\.\,\?\。\，\-\——\=\;\@\！\!\_\+]{2,100}$/;

//身份证  18位
export const idCard = /^[0-9]\d{16}(\d|x|X)$/;

// 泰postalCode格式
export const postalThaiReg = /^[0-9]{5}$/;

// 泰文地址格式
export const addressThaiReg = /^[0-9a-zA-Z\u0E00-\u0E7F  #'.,-/&()]{5,100}$/;

// 備註格式(逐字選取不符合備註格式的字元)
export const remarkReg = /[^a-zA-Z\u0E00-\u0E7F ]/g;

// 泰达币ERC20
export const usdtERC20Reg = /^(0x|0X)[a-fA-F0-9]{40}$/;
// 泰达币TRC20
export const usdtTRC20Reg = /^(T)[a-zA-Z0-9]{33}$/;
// 泰達幣Wallet Name
export const cryptoWalletNameThai = /^[0-9a-zA-Z\u0E00-\u0E7F ]{1,20}$/; 
